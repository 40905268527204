var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-content",class:{ small: _vm.formActive }},[(!_vm.formActive)?_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header-start"},[_c('h3',[_vm._v("BA - Outlet")]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary",on:{"click":function($event){_vm.formActive = true;
              _vm.$router
                .push(Object.assign({}, _vm.$route,
                  {query: Object.assign({}, _vm.$route.query,
                    {add: 'add'})}))
                .catch(function (err) {});}}},[_vm._v(" Add ")]),_c('button',{staticClass:"button light-grey",on:{"click":_vm.ExportOutletBA}},[_vm._v(" Export ")])])]),_c('div',{staticClass:"page-header-end"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchOutlet),expression:"searchOutlet"}],staticClass:"input",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.searchOutlet)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchOutlet=$event.target.value}}})]),_c('div',{staticClass:"page-header-end"},[_c('ul',[_c('li',[_c('p',[_vm._v("All Outlet:")]),_c('h3',[_vm._v(_vm._s(_vm.totalShopBA))])]),_c('li',[_c('div',{staticClass:"select"},[_c('supervisor',{attrs:{"data":_vm.supervisor,"itemName":"name"},model:{value:(_vm.selectedSupervisor),callback:function ($$v) {_vm.selectedSupervisor=$$v},expression:"selectedSupervisor"}})],1)]),_c('li',[_c('div',{staticClass:"select"},[_c('supervisor',{attrs:{"data":_vm.status,"itemName":"name"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)])])])]):_vm._e(),_c('div',{staticClass:"table-container scrollable"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.getShop),function(i,index){return _c('tr',{key:index},[_c('td',{staticClass:"clickable",on:{"click":function($event){_vm.formActive = true;
                _vm.$router
                  .push({
                    query: {
                      selectedSupervisor: _vm.$route.query.selectedSupervisor,
                      selectedStatus: _vm.$route.query.selectedStatus,
                      page: _vm.$route.query.page,
                      perPage: _vm.pages.perPage,
                      searchOutlet: _vm.$route.query.searchOutlet,
                      id: i._id,
                    },
                  })
                  .catch(function (err) {});}}},[_vm._v(" "+_vm._s(i.shopCode)+" ")]),_c('td',[_vm._v(_vm._s(i.name))]),_c('td',[_vm._v(_vm._s(_vm.getChampaOutlet(i.status)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(i.recruitDate)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(i.quitDate)))]),_c('td',[_vm._v(_vm._s(i.zoneCode))]),_c('td',[_vm._v(_vm._s(i.workingID))]),_c('td',[_vm._v(_vm._s(i.employeeID))]),_c('td',[_vm._v(_vm._s(i.fullname))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(i.joinDate)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(i.adminName))])])}),0)])]),_c('paginate',{attrs:{"total":_vm.all},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.formActive)?_c('outletForm',{on:{"close":function($event){_vm.formActive = false}}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"is-xs"},[_vm._v("Outlet Code")]),_c('th',{staticClass:"is-sm"},[_vm._v("Outlet Name")]),_c('th',{staticClass:"is-sm"},[_vm._v("Outlet Status")]),_c('th',{staticClass:"is-sm"},[_vm._v("Recurit Date")]),_c('th',{staticClass:"is-sm"},[_vm._v("Quit Date")]),_c('th',{staticClass:"is-xxs"},[_vm._v("DT Code")]),_c('th',{staticClass:"is-xs"},[_vm._v("Working ID")]),_c('th',{staticClass:"is-xs"},[_vm._v("Employee ID")]),_c('th',{staticClass:"is-xs"},[_vm._v("Champa Name")]),_c('th',{staticClass:"is-xs"},[_vm._v("Join Date")]),_c('th',{staticClass:"is-xs text-right"},[_vm._v("Supervisor")])])])}]

export { render, staticRenderFns }