<template>
  <div class="page-container">
    <div class="sub-sidebar">
      <div class="sub-sidebar-header">
        <ul>
          <li>
            <date-picker
              v-model="startDate"
              type="date"
              placeholder="Start Date"
            >
            </date-picker>
          </li>
          <li>
            <date-picker v-model="endDate" type="date" placeholder="End Date">
            </date-picker>
          </li>
        </ul>
      </div>
      <div class="sub-sidebar-list scrollable">
        <ul>
          <li
            v-for="(i, index) in groupDate"
            :key="index"
            :class="{ selected: i._id === $route.query.id }"
            @click="
              selectComponent = 'ProblemReport';
              $router
                .push({
                  name: 'problem_report',
                  query: {
                    id: i._id,
                  },
                })
                .catch((err) => {});
            "
          >
            <span>{{ i.date }}</span>
            <p>
              <span>{{ i.count }} </span>
            </p>
          </li>
        </ul>
      </div>
    </div>

    <component :is="selectComponent"></component>
    <!-- <component
      v-if="formDailyReport"
      :is="'DailyReportForm'"
      @close="formDailyReport = false"
    ></component> -->
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProblemReport from "../components/problem-report-list.vue";
export default {
  components: {
    DatePicker,
    ProblemReport,
  },
  data: () => ({
    selectComponent: "",
    groupDate: [],
    // formDailyReport: false,
    startDate: "",
    endDate: "",
  }),
  watch: {
    "$route.query.startDate": {
      handler(val) {
        this.FetchGroupDate();
      },
      immediate: true,
    },
    startDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { startDate: val },
          })
          .catch((err) => {});
      },
      // immediate: true,
    },
    "$route.query.endDate": {
      handler(val) {
        this.FetchGroupDate();
      },
      immediate: true,
    },
    endDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { endDate: val },
          })
          .catch((err) => {});
      },
      // immediate: true,
    },
  },
  created() {
    this.FetchGroupDate();
  },
  methods: {
    async FetchGroupDate() {
      const res = await this.$axios.get(
        `${this.$server}api/problem-report-group-date?startDate=${
          this.startDate ?? ""
        }&endDate=${this.endDate ?? ""}`
      );
      this.groupDate = res.data.mapGroupDate;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>