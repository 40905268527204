var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"sub-sidebar"},[_c('div',{staticClass:"sub-sidebar-header"},[_c('ul',[_c('li',[_c('date-picker',{attrs:{"type":"date","placeholder":"Start Date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('li',[_c('date-picker',{attrs:{"type":"date","placeholder":"End Date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)])]),_c('div',{staticClass:"sub-sidebar-list scrollable"},[_c('ul',_vm._l((_vm.groupDate),function(i,index){return _c('li',{key:index,class:{ selected: i._id === _vm.$route.query.id },on:{"click":function($event){_vm.selectComponent = 'ProblemReport';
            _vm.$router
              .push({
                name: 'problem_report',
                query: {
                  id: i._id,
                },
              })
              .catch(function (err) {});}}},[_c('span',[_vm._v(_vm._s(i.date))]),_c('p',[_c('span',[_vm._v(_vm._s(i.count)+" ")])])])}),0)])]),_c(_vm.selectComponent,{tag:"component"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }