<template>
  <div class="widget">
    <ul class="tab-group">
      <li
        :class="{ active: selectedTab === 'champa' }"
        @click="selectedTab = 'champa'"
      >
        Champa
      </li>
      <li
        class="ba"
        :class="{ 'active ba': selectedTab === 'ba' }"
        @click="selectedTab = 'ba'"
      >
        BA
      </li>
    </ul>
    <div class="widget-card" v-if="selectedTab === 'champa'">
      <h3 class="card-title"><span>Champa</span> - Daily Report</h3>
      <ul v-for="(a, drchampa) in summaryDailyReport" :key="'A' + drchampa">
        <li>
          <p>Ladies Absenced</p>
          <span>{{ a.absence_champa }}</span>
        </li>
        <li>
          <p>Closed Outlet</p>
          <span>{{ a.shopClosed_champa }}</span>
        </li>
        <li>
          <p>Ladies in Quarantine</p>
          <span>{{ a.quarantine_champa }}</span>
        </li>
        <li>
          <p>Ladies with Covid Infection</p>
          <span>{{ a.addictedTocovid_champa }}</span>
        </li>
      </ul>

      <h3 class="card-title"><span>Champa</span> - Health Check</h3>
      <ul>
        <li>
          <p>Uncheck</p>
          <span>-</span>
        </li>
        <li>
          <p>Fever with 37.5 Celsius</p>
          <span>-</span>
        </li>
        <li>
          <p>Cough, Sore Throat</p>
          <span>-</span>
        </li>
        <li>
          <p>Shortness of Breath</p>
          <span>-</span>
        </li>
        <li>
          <p>Running Nose</p>
          <span>-</span>
        </li>
      </ul>

      <h3 class="card-title"><span>Champa</span> - Vaccinate</h3>
      <ul v-for="(b, vcchampa) in summary" :key="'B' + vcchampa">
        <li>
          <p>0 Dose</p>
          <span>{{ b.Champa_VaccinesDose0 }}</span>
        </li>
        <li>
          <p>Dose 1</p>
          <span>{{ b.Champa_VaccinesDose1 }}</span>
        </li>
        <li>
          <p>Dose 2</p>
          <span>{{ b.Champa_VaccinesDose2 }}</span>
        </li>
        <li>
          <p>Dose 3</p>
          <span>{{ b.Champa_VaccinesDose3 }}</span>
        </li>
      </ul>
    </div>

    <div class="widget-card ba" v-if="selectedTab === 'ba'">
      <h3 class="card-title"><span>BA</span> - Daily Report</h3>
      <ul v-for="(c, drba) in summaryDailyReport" :key="'C' + drba">
        <li>
          <p>Ladies Absenced</p>
          <span>{{ c.absence_BA }}</span>
        </li>
        <li>
          <p>Closed Outlet</p>
          <span>{{ c.shopClosed_BA }}</span>
        </li>
        <li>
          <p>Ladies in Quarantine</p>
          <span>{{ c.quarantine_BA }}</span>
        </li>
        <li>
          <p>Ladies with Covid Infection</p>
          <span>{{ c.addictedTocovid_BA }}</span>
        </li>
      </ul>

      <h3 class="card-title"><span>BA</span> - Health Check</h3>
      <ul>
        <li>
          <p>Uncheck</p>
          <span>-</span>
        </li>
        <li>
          <p>Fever with 37.5 Celsius</p>
          <span>-</span>
        </li>
        <li>
          <p>Cough, Sore Throat</p>
          <span>-</span>
        </li>
        <li>
          <p>Shortness of Breath</p>
          <span>-</span>
        </li>
        <li>
          <p>Running Nose</p>
          <span>-</span>
        </li>
      </ul>

      <h3 class="card-title"><span>BA</span> - Vaccinate</h3>
      <ul v-for="(d, vcba) in summary" :key="'D' + vcba">
        <li>
          <p>0 Dose</p>
          <span>{{ d.BA_VaccinesDose0 }}</span>
        </li>
        <li>
          <p>Dose 1</p>
          <span>{{ d.BA_VaccinesDose1 }}</span>
        </li>
        <li>
          <p>Dose 2</p>
          <span>{{ d.BA_VaccinesDose2 }}</span>
        </li>
        <li>
          <p>Dose 3</p>
          <span>{{ d.BA_VaccinesDose3 }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["summary", "summaryDailyReport"],
  data: () => ({
    selectedTab: "champa",
  }),
};
</script>

<style lang="scss" scoped>
$ba-1: #d96098;

.tab-group {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 5px;
  margin-bottom: 10px;
  li {
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    padding: 5px 0;
    font-size: var(--xsm-font);
    border-radius: 5px;
    background-color: rgba(var(--primary-color), 0.08);
    color: var(--primary-color);
    &.active {
      background-color: var(--primary-color);
      color: #fff;
    }
    &.ba {
      background-color: rgba($ba-1, 0.15);
      color: $ba-1;
      &.active {
        background-color: $ba-1;
        color: #fff;
      }
    }
  }
}
.widget {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  padding: 10px 20px;
  border-left: 1px solid var(--border-color);
  .widget-card {
    border-radius: 5px;
    .card-title {
      font-size: var(--sm-font);
      margin-bottom: 10px;
      color: var(--primary-color);
      span {
        font-weight: 700;
      }
    }
    ul {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      li {
        padding: 3px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--primary-color);
        font-size: var(--xsm-font);
        &:first-child {
          border-top: 1px solid var(--primary-color);
        }
        span {
          color: #fff;
          font-weight: 700;
          background-color: var(--primary-color);
          padding: 1px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
        }
      }
    }
    &.ba {
      .card-title {
        color: $ba-1;
        span {
          font-weight: 700;
        }
      }

      ul li {
        border-bottom: 1px solid $ba-1;
        font-size: var(--xsm-font);
        &:first-child {
          border-top: 1px solid $ba-1;
        }
        span {
          background-color: $ba-1;
        }
      }
    }
  }
}
</style>
