<template>
  <div class="page-container">
    <div class="page-content scrollable-auto">
      <div class="page-header">
        <div class="page-header-start">
          <h3 v-if="$route.query.type === 'champa'">
            No Champa Promotors Over 14 Days
          </h3>
          <h3 v-else>No BA Promotors Over 14 Days</h3>
          <div class="button-group">
            <button class="button grey" @click="close">Cancel</button>
          </div>
        </div>

        <div class="page-header-end">
          <ul>
            <li>
              <p>All Outlet:</p>
              <h3>{{ totalDuration }}</h3>
            </li>
          </ul>
        </div>
      </div>

      <div class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Outlet Code</th>
              <th class="is-sm">Outlet Name</th>
              <th class="is-sm">Outlet Status</th>
              <th class="is-sm">Recurit Date</th>
              <th class="is-xxs">DT Code</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in duration" :key="index">
              <td>{{ i.shopCode }}</td>
              <td>{{ i.shopname }}</td>
              <td>{{ status(i.shopStatus) }}</td>
              <td>{{ formatDate(i.recruitDate) }}</td>
              <td>{{ i.DT }}</td>
              <td class="text-right">{{ i.supervisor }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate v-model="page" :total="all" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import paginate from "../paginate.vue";
export default {
  components: {
    paginate,
  },
  data: () => ({
    duration: [],
    totalDuration: "",
    type: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
  }),
  watch: {
    //type
    "$route.query.type": {
      handler() {
        this.overdays();
      },
      immediate: true,
    },
    type: {
      handler(val) {
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, type: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.overdays();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
  },

  created() {
    this.overdays();
  },
  computed: {
    status() {
      // const n = [{
      //   oldName: 'active',
      //   newName: 'ssj'
      // }]
      return (val) => {
        if (val === "pending") return "no champa";
        return val;
      };
    },
    formatDate() {
      return (val) => {
        if (val) return moment(val).format("YYYY-MM-DD");
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async overdays() {
      const res = await this.$axios.get(
        `${this.$server}api/over-days?typeShop=${this.$route.query.type}&page=${this.$route.query.page}&perPage=${this.pages.perPage}`
      );
      this.duration = res.data.summaryShopDuration;
      this.totalDuration = res.data.totalShopDuration;
      this.all = res.data.totalShopDuration;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  position: absolute;
  top: -5px;
  background: #ffff;
  height: 100%;
  width: 100%;
  z-index: 9999;
  //   padding-right: 15px;
}
.table-container {
  padding: 0 10px 0 0;
  .table {
    width: 100%;
  }
  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
