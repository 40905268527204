<template>
  <div class="page-container">
    <div class="sub-sidebar">
      <div class="sub-sidebar-header">
        <ul>
          <li>
            <!-- <input
              type="text"
              class="input is-small"
              placeholder="Select start Date"
            /> -->
            <date-picker
              v-model="startDate"
              type="date"
              placeholder="Start Date"
            >
            </date-picker>
          </li>
          <li>
            <!-- <input
              type="text"
              class="input is-small"
              placeholder="Select end Date"
            /> -->
            <date-picker v-model="endDate" type="date" placeholder="End Date">
            </date-picker>
          </li>
        </ul>
      </div>
      <ul class="sub-sidebar-list scrollable">
        <li
          v-for="(i, index) in Tasks"
          :key="index"
          :class="{ selected: i._id === $route.query.id }"
          @click="
            selectedStatus = 'healthCheckList';
            $router
              .push({
                name: 'health_check',
                query: {
                  id: i._id,
                },
              })
              .catch((err) => {});
          "
        >
          <span>{{ i.date }}</span>
          <p>
            <span>{{ i.checked }}</span>
            <span>/</span>
            <span>{{ i.userId }}</span>
          </p>
        </li>
      </ul>
    </div>

    <component :is="selectedStatus"></component>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import healthCheckList from "../components/health-check-list.vue";
export default {
  data: () => ({
    checkedAll: false,
    checked: false,
    Tasks: [],
    _id: "",
    selectedStatus: "",
    startDate: "",
    endDate: "",
  }),
  components: {
    DatePicker,
    healthCheckList,
  },
  watch: {
    "$route.query.startDate": {
      handler(val) {
        this.FetchTasks();
      },
      immediate: true,
    },
    startDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { startDate: val },
          })
          .catch((err) => {});
      },
      // immediate: true,
    },
    "$route.query.endDate": {
      handler(val) {
        this.FetchTasks();
      },
      immediate: true,
    },
    endDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { endDate: val },
          })
          .catch((err) => {});
      },
      // immediate: true,
    },
  },
  created() {
    this.FetchTasks();
  },
  methods: {
    async FetchTasks() {
      const res = await this.$axios.get(
        `${this.$server}api/get-tasks?startDate=${
          this.startDate ?? ""
        }&endDate=${this.endDate ?? ""}`
      );
      this.Tasks = res.data.mapTasks;
    },
    async DeleteTask(id) {
      await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.delete(`${this.$server}api/delete-tasks/${id}`);
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          this.FetchTasks();
        }
      });
    },
    async downloadCheckList(_id) {
      try {
        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        const res = await this.$axios.post(
          `${this.$server}api/download-checklist/` + _id,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "checklist-template.xlsx");
        document.body.appendChild(link);
        link.click();
        // await this.$store.dispatch("completed")
      } catch (err) {
        // await this.$store.dispatch("error")
        throw new Error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>