<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActive }">
      <div class="page-header" v-if="!formActive">
        <div class="page-header-start">
          <h3 v-if="$route.query.type === 'champa'">Champa - Outlet</h3>
          <h3 v-else>BA - Outlet</h3>
          <div class="button-group">
            <button class="button primary sm" @click="
              formActive = true;
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  add: 'add',
                },
              })
              .catch((err) => { });
            ">
              Add
            </button>
            <button class="button light-grey sm" @click="ExportOutletChampa" v-if="$route.query.type === 'champa'">
              Export
            </button>
            <button class="button light-grey sm" @click="ExportOutletBA" v-else>
              Export
            </button>
          </div>
          <input v-model="searchOutlet" type="text" class="input sm" placeholder="Search..." />
        </div>

        <div class="page-header-end">
          <ul>
            <li v-if="isLoading" class="loading_nav"></li>
            <li v-else>
              <p>Total Outlet:</p>
              <h3>{{ totalShop }}</h3>
            </li>
            <li>
              <div class="select sm">
                <filterSelect v-model="selectedSupervisor" :data="supervisor" itemName="name" optionName="Supervisor" />
              </div>
            </li>
            <li>
              <div class="select sm">
                <filterSelect v-model="selectedStatus" :data="status" itemName="name" optionName="Status" />
              </div>
            </li>
            <li>
              <div class="select sm">
                <filterSelect v-model="selectDT" :data="getDT" itemName="zoneCode" optionName="DT" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- isLoading -->
      <div v-if="isLoading" class="table-container scrollable">
        <div v-for="(l, indexLoading) in getShop" :key="indexLoading" class="loading"></div>
      </div>
      <div v-else class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Outlet Code</th>
              <th class="is-sm">Outlet Name</th>
              <th class="is-sm">Outlet Status</th>
              <th class="is-sm">Recurit Date</th>
              <th class="is-sm">Quit Date</th>
              <th class="is-xxs">DT Code</th>
              <th class="is-xs">Working ID</th>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th class="is-xs">Join Date</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in getShop" :key="index">
              <td class="clickable" @click="
                formActive = true;
              $router
                .push({
                  query: {
                    selectedSupervisor: $route.query.selectedSupervisor,
                    selectedStatus: $route.query.selectedStatus,
                    page: $route.query.page,
                    perPage: pages.perPage,
                    searchOutlet: $route.query.searchOutlet,
                    selectDT: $route.query.selectDT,
                    type: $route.query.type,
                    shopId: i._id,
                  },
                })
                .catch((err) => { });
              ">
                {{ i.shopCode }}
              </td>
              <td>{{ i.name }}</td>
              <td>{{ getChampaOutlet(i.status) }}</td>
              <td>{{ formatDate(i.recruitDate) }}</td>
              <td>{{ formatDate(i.quitDate) }}</td>
              <td>{{ i.zoneCode }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ i.employeeID }}</td>
              <td>{{ i.fullname }}</td>
              <td>{{ formatDate(i.joinDate) }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- pagination -->
      <paginate v-model="page" :total="all" />
    </div>

    <transition name="slide-fade">
      <!-- <component
      v-if="formActive"
      :is="'outletForm'"
      @close="formActive = false"
    ></component> -->
      <outletForm v-if="formActive" @close="formActive = false" />
    </transition>

    <!-- <export-date
      v-if="formActiveExport"
      @closeExport="formActiveExport = false"
    /> -->
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import filterSelect from "../components/filter/filter.vue";
import outletForm from "../components/form/outlet.vue";
import ExportDate from "../components/export/export-date.vue";
import moment from "moment";
export default {
  components: {
    filterSelect,
    paginate,
    outletForm,
    ExportDate,
  },
  data: () => ({
    status: [
      {
        _id: "setup",
        name: "Setup",
      },
      {
        _id: "pending",
        name: "No Champa",
      },
      {
        _id: "active",
        name: "Active",
      },
      {
        _id: "quit",
        name: "Quit",
      },
    ],
    isLoading: false,
    formActive: false,
    formActiveExport: false,
    getShop: [],
    supervisor: [],
    getDT: [],
    totalShop: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    selectedSupervisor: "",
    selectedStatus: "",
    searchOutlet: "",
    selectDT: "",
    type: "",
    timeout: null,
  }),
  watch: {
    "$route.query.status": {
      handler(val) {
        if (val === "close") {
          this.formActive = false;
        }
      },
    },
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, selectedSupervisor: val },
        });
      },
    },
    //status
    "$route.query.selectedStatus": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedStatus: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, selectedStatus: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    // "$route.query.searchOutlet": {
    //   async handler(val) {
    //     this.isLoading = true;
    //     await this.FetchShop();

    //     setTimeout(() => {
    //       this.isLoading = false;
    //     }, 2000);
    //   },
    //   immediate: true,
    // },

    searchOutlet(newValue) {
      // handler(val) {
      //   this.$router.push({
      //     ...this.$route,
      //     query: { ...this.$route.query, searchOutlet: val },
      //   });
      // },

      this.isLoading = true;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$router
          .push({
            ...this.$route,
            query: { ...this.$route.query, searchOutlet: newValue },
          })
          .catch((err) => { });

        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }, 3000);
    },
    //selectDT
    "$route.query.selectDT": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    selectDT: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, selectDT: val },
        });
      },
    },
    //type
    "$route.query.type": {
      handler() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.FetchShop();
        }, this.$timeout);
      },
      immediate: true,
    },
    type: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: { ...this.$route.query, type: val },
        });
      },
    },
    $route() {
      this.FetchShop();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchShop();
    this.FetchSupervisor();
    this.FetchDT();
  },
  computed: {
    getChampaOutlet() {
      // const n = [{
      //   oldName: 'active',
      //   newName: 'ssj'
      // }]
      return (val) => {
        if (val === "pending") return "no champa";
        return val;
      };
    },
    formatDate() {
      return (val) => {
        if (val) return moment(val).format("YYYY-MM-DD");
      };
    },
  },
  methods: {
    async FetchDT() {
      const res = await this.$axios.get(`${this.$server}api/zone-get`);
      this.getDT = res.data.mapGetZone;
    },
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchShop() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-shop-champa?adminId=${this.$route.query.selectedSupervisor}&status=${this.$route.query.selectedStatus}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchOutlet}&zoneId=${this.$route.query.selectDT}&typeShop=${this.$route.query.type}`
      );
      this.getShop = res.data.aggreShopChampa;
      this.totalShop = res.data.total;
      this.all = res.data.total;
      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 1000);
    },
    async ExportOutletChampa() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-shop-champa`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OutletChampa.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          // this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
    async ExportOutletBA() {
      try {
        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-shop-BA`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OutletBA.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          // this.$emit("closeExport");
          // await this.$store.dispatch("completed")
        }, 500);
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 0 10px 0 0;

  .table {
    width: 100%;
  }

  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}

.page-header-start {
  input {
    margin-left: 20px;
    border-color: var(--primary-color);
  }
}

.page-header-end {
  ul {
    li {
      p {
        font-size: var(--md-font) !important;
      }

      h3 {
        font-size: var(--md-font) !important;
      }
    }
  }
}
</style>
