<template>
  <div class="page-content">
    <ul class="header">
      <li>
        <h3>Employee ID</h3>
        <h3>Report Detail</h3>
      </li>
    </ul>
    <ul class="scrollable">
      <li v-for="(i, index) in groupDateId" :key="index">
        <h3>{{ i.champaID }}</h3>
        <h3>{{ i.reportDetail }}</h3>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    groupDateId: [],
  }),
  watch: {
    // $route() {
    //   this.FetchGroupDateId();
    // },
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.FetchGroupDateId();
        }
      },
      immediate: true,
    },
  },
  // created() {
  //   if (this.$route.query.id) {
  //     this.FetchGroupDateId();
  //   }
  // },
  methods: {
    FetchGroupDateId() {
      this.$axios
        .get(
          `${this.$server}api/problem-report-find-id/` + this.$route.query.id
        )
        .then((res) => {
          this.groupDateId = res.data.findIdGroupDate;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  margin-bottom: 20px;
  h3 {
    font-size: var(--lg-font);
    font-weight: 700;
  }
}
ul {
  width: 100%;
  li {
    padding: 10px 0;
    display: flex;
    border-width: 0 0 1px 0;
    border-color: var(--border-color);
    border-style: solid;
    h3 {
      flex-grow: 1;
    }
    p {
      width: 200px;
    }
    span {
      width: 100px;
      text-align: right;
      padding-right: 20px;
    }
  }
  &.header li {
    border-bottom: 1px solid var(--border-color);
    font-weight: 700;
    position: sticky;
    top: 0;
    padding-top: 0;
    padding-right: 17px;
    h3 {
      flex-grow: 1;
      font-weight: 700;
    }
  }
}
</style>
