<template>
  <section class="section">
    <div class="input-container">
      <div class="field">
        <label for="" class="label">Enter you code</label>
        <div class="control">
          <input
            v-model="email"
            type="text"
            class="input"
            placeholder=""
            style="display: none"
          />
          <input
            v-model="password"
            type="password"
            class="input"
            placeholder="password"
            @keyup.enter="login"
          />
        </div>
        <button class="button primary" @click="login">Login</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: "user@gmail.com",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      await this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$axios.defaults.headers["Authorization"] =
            this.$store.getters.getToken;
          this.$axios.post(`${this.$server}api/adminlog-add`);
          // this.$router.push({
          //   path: "/",
          // });
          this.$swal({
            position: "top",
            icon: "success",
            title: "ຍິນດີຕ້ອນຮັບ",
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch((err) => {
          // this.$swal("ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ", "ກະລຸນາກວດສອບລະຫັດຄືນໃໝ່", "error");
          this.$swal({
            position: "top",
            icon: "error",
            title: "ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
            showConfirmButton: false,
            timer: 1000,
          });
          throw new Error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  position: fixed;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  .input-container {
    max-width: 600px;
  }
}
button {
  margin-top: 10px;
}
</style>