<template>
  <div class="page-content">
    <ul class="header">
      <li>
        <h3>Employee ID</h3>
        <h3>Symptoms</h3>
        <span>Status</span>
      </li>
    </ul>
    <ul class="scrollable">
      <li v-for="(i, index) in task.todo" :key="index">
        <h3>{{ i.employeeID }}</h3>
        <h3>{{ i.checkbox[0].name }}</h3>
        <span v-if="!i.status" class="unchecked"
          ><i class="fas fa-times-circle"></i
        ></span>
        <span v-else class="checked"><i class="fas fa-check-square"></i></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    task: {},
    todo: [],
    checkbox: [],
  }),
  watch: {
    // $route() {
    //   this.FetchTaskId();
    // },
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.FetchTaskId();
        }
      },
      immediate: true,
    },
  },
  // created() {
  //   if (this.$route.query.id) {
  //     this.FetchTaskId();
  //   }
  // },
  methods: {
    FetchTaskId() {
      this.$axios
        .get(`${this.$server}api/find-id-task/` + this.$route.query.id)
        .then((res) => {
          this.task = res.data.findIdTask;
          this.todo = res.data.findIdTask.todo;
          this.checkbox = this.todo.checkbox;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  margin-bottom: 20px;
  h3 {
    font-size: var(--lg-font);
    font-weight: 700;
  }
}
ul {
  width: 100%;
  li {
    padding: 10px 0;
    display: flex;
    border-width: 0 0 1px 0;
    border-color: var(--border-color);
    border-style: solid;
    h3 {
      flex-grow: 1;
      text-align: center;
      &:first-child {
        text-align: left;
        max-width: 150px;
        width: 100%;
      }
    }
    p {
      padding: 0 30px;
    }
    span {
      width: 100px;
      text-align: right;
      padding-right: 20px;
      &.checked i {
        color: var(--primary-color);
      }
      &.unchecked i {
        color: var(--alert-color);
      }
    }
  }
  &.header li {
    border-bottom: 1px solid var(--border-color);
    font-weight: 700;
    position: sticky;
    top: 0;
    padding-top: 0;
    padding-right: 17px;
    h3 {
      flex-grow: 1;
      font-weight: 700;
    }
  }
}
</style>
