<template>
  <div>
    <div class="page-container">
      <div class="page-content scrollable-auto">
        <div class="page-header no-border">
          <div class="page-header-start">
            <h3>Overall</h3>
          </div>
          <div class="page-header-end">
            <ul>
              <li
                :class="{ active: selectedTab === 'champa' }"
                @click="selectedTab = 'champa'"
              >
                Champa
              </li>
              <li
                class="ba"
                :class="{ 'active ba': selectedTab === 'ba' }"
                @click="selectedTab = 'ba'"
              >
                BA
              </li>
            </ul>
          </div>
        </div>

        <!-- selectTab champa -->
        <div class="overall" v-if="selectedTab === 'champa'">
          <div class="overall-card">
            <ul v-for="(s, index) in currentShop" :key="index">
              <li>
                <label>
                  <h3>Champa-Outlet</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Total Outlet</span>
                  <h3>{{ s.totalShops_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Setup Outlet</span>
                  <h3>{{ s.setup_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Actual Outlet </span>
                  <h3>{{ s.Actual_Champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Active Outlet </span>
                  <h3>{{ s.ActiveShop_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Month to Date</span>
                  <h3>{{ s.NewShop_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>No Champa Promotors</span>
                  <h3>{{ s.PendingShop_champa }}</h3>
                </label>
              </li>
              <li
                @click="
                  formActiveOverDays = true;
                  $router
                    .push({
                      ...$route,
                      query: {
                        ...$route.query,
                        type: 'champa',
                        page: 1,
                        perPage: 10,
                      },
                    })
                    .catch((err) => {});
                "
              >
                <label>
                  <span>No Champa Promotors Over 14 Days</span>
                  <h3>{{ duration[0].OverDays_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Quitted</span>
                  <h3>{{ s.QuittedShop_champa }}</h3>
                </label>
              </li>
            </ul>
          </div>

          <div class="overall-card">
            <ul v-for="(c, index) in currentUser" :key="index">
              <li>
                <label>
                  <h3>Champa-Lady</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Total</span>
                  <h3>{{ c.totalUsers_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Actual </span>
                  <h3>{{ c.Actual_Champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Active </span>
                  <h3>{{ c.Working_Champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Pending</span>
                  <h3>{{ c.PendingLadies_Champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Month to Date</span>
                  <h3>{{ currentShop[0].NewLadies_Champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Resigned</span>
                  <h3>{{ c.Resigned_champa }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Recruiting</span>
                  <h3>{{ recuritChampa }}</h3>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <!-- selectTab BA -->
        <div class="overall" v-if="selectedTab === 'ba'">
          <div class="overall-card">
            <ul v-for="(s, index) in currentShop" :key="index">
              <li>
                <label>
                  <h3>BA-Outlet</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Total Outlet</span>
                  <h3>{{ s.totalShops_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Setup Outlet</span>
                  <h3>{{ s.setup_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Actual Outlet </span>
                  <h3>{{ s.Actual_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Active Outlet </span>
                  <h3>{{ s.ActiveShop_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Month to Date</span>
                  <h3>{{ s.NewShop_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>No BA</span>
                  <h3>{{ s.PendingShop_BA }}</h3>
                </label>
              </li>
              <li
                @click="
                  formActiveOverDays = true;
                  $router
                    .push({
                      ...$route,
                      query: {
                        ...$route.query,
                        type: 'BA',
                        page: 1,
                        perPage: 10,
                      },
                    })
                    .catch((err) => {});
                "
              >
                <label>
                  <span>No Champa Promotors Over 14 Days</span>
                  <h3>{{ duration[0].OverDays_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Quitted</span>
                  <h3>{{ s.QuittedShop_BA }}</h3>
                </label>
              </li>
            </ul>
          </div>

          <div class="overall-card">
            <ul v-for="(c, index) in currentUser" :key="index">
              <li>
                <label>
                  <h3>BA-Lady</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Total</span>
                  <h3>{{ c.totalUsers_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Actual </span>
                  <h3>{{ c.Actual_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Active </span>
                  <h3>{{ c.Working_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Pending</span>
                  <h3>{{ c.PendingLadies_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Month to Date</span>
                  <h3>{{ currentShop[0].NewLadies_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Resigned</span>
                  <h3>{{ c.Resigned_BA }}</h3>
                </label>
              </li>
              <li>
                <label>
                  <span>Recruiting</span>
                  <h3>{{ recuritBA }}</h3>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="page-header no-border">
          <div class="page-header-start">
            <h3>Summary History</h3>
          </div>
          <div class="page-header-end">
            <div class="custom-yearmonth small">
              <!-- <p>Select Month</p> -->
              <div class="control">
                <DatePicker
                  v-model="month"
                  type="month"
                  placeholder="Select Month"
                  input-class="md-input"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(i, index) in getSummary" :key="index" class="card">
          <div class="group-container">
            <!-- Champa Outlet -->
            <div class="group-item">
              <div class="list-container">
                <div class="list-title">
                  <span><i class="fa-solid fa-shop"></i></span>
                  <h3>Champa Outlet</h3>
                </div>
                <ul>
                  <!-- <li class="list-item champa-1">
                  <div class="label-item">
                    <p>Total Outlet</p>
                  </div>
                  <h3>{{ i.totalShops_champa }}</h3>
                </li> -->
                  <!-- <li class="list-item champa-1">
                  <div class="label-item">
                    <p>Actual Outlet</p>
                  </div>
                  <h3>{{ i.Actual_Champa }}</h3>
                </li> -->
                  <li class="list-item champa-1">
                    <div class="label-item">
                      <p>Active Outlet</p>
                    </div>
                    <h3>{{ i.ActiveShop_Champa }}</h3>
                  </li>
                  <li class="list-item champa-1 child">
                    <div class="label-item">
                      <p>No Champa Promoter</p>
                    </div>
                    <h3>{{ i.PendingShop_Champa2 }}</h3>
                  </li>
                  <li class="list-item champa-1">
                    <div class="label-item">
                      <p>Outlet Month to Date</p>
                    </div>
                    <h3>{{ i.NewShop_Champa }}</h3>
                  </li>
                  <li class="list-item champa-1">
                    <div class="label-item">
                      <p>Quited</p>
                    </div>
                    <h3>{{ i.QuitShop_Champa }}</h3>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Champa Promoter -->
            <div class="group-item">
              <div class="list-container">
                <div class="list-title">
                  <span><i class="fa-solid fa-user-group"></i></span>
                  <h3>Champa Promoter</h3>
                </div>
                <ul>
                  <li class="list-item champa-2">
                    <div class="label-item">
                      <p>Active</p>
                    </div>
                    <h3>{{ i.LadiesChampa_working }}</h3>
                  </li>
                  <li class="list-item champa-2 child">
                    <div class="label-item">
                      <p>Month to Date</p>
                    </div>
                    <h3>{{ i.NewLadies_Champa }}</h3>
                  </li>
                  <li class="list-item champa-2">
                    <div class="label-item">
                      <p>Resigned</p>
                    </div>
                    <h3>{{ i.ResignedLadies_Champa }}</h3>
                  </li>
                  <li class="list-item grey-1">
                    <div class="label-item">
                      <p>Recruiting</p>
                    </div>
                    <h3>{{ i.RecruitingLadies_Champa }}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="group-container">
            <!-- BA Outlet -->
            <div class="group-item">
              <div class="list-container">
                <div class="list-title">
                  <span><i class="fa-solid fa-shop"></i></span>
                  <h3>BA Outlet</h3>
                </div>
                <ul>
                  <!-- <li class="list-item ba-1">
                  <div class="label-item">
                    <p>Total Outlet</p>
                  </div>
                  <h3>{{ i.totalShops_BA }}</h3>
                </li> -->
                  <!-- <li class="list-item ba-1">
                  <div class="label-item">
                    <p>Actual - Outlet</p>
                  </div>
                  <h3>{{ i.Actual_BA }}</h3>
                </li> -->
                  <li class="list-item ba-1">
                    <div class="label-item">
                      <p>Active - Outlet</p>
                    </div>
                    <h3>{{ i.ActiveShop_BA }}</h3>
                  </li>
                  <li class="list-item ba-1 child">
                    <div class="label-item">
                      <p>No BA</p>
                    </div>
                    <h3>{{ i.PendingShop_BA2 }}</h3>
                  </li>
                  <li class="list-item ba-1">
                    <div class="label-item">
                      <p>Outlet Month to Date</p>
                    </div>
                    <h3>{{ i.NewShop_BA }}</h3>
                  </li>
                  <li class="list-item ba-1">
                    <div class="label-item">
                      <p>Quited</p>
                    </div>
                    <h3>{{ i.QuitShop_BA }}</h3>
                  </li>
                </ul>
              </div>
            </div>

            <!-- BA Staff -->
            <div class="group-item">
              <div class="list-container">
                <div class="list-title">
                  <span><i class="fa-solid fa-user-group"></i></span>
                  <h3>BA</h3>
                </div>
                <ul>
                  <li class="list-item ba-2">
                    <div class="label-item">
                      <p>Active</p>
                    </div>
                    <h3>{{ i.LadiesBA_working }}</h3>
                  </li>
                  <li class="list-item ba-2 child">
                    <div class="label-item">
                      <p>Month to Date</p>
                    </div>
                    <h3>{{ i.NewLadies_BA }}</h3>
                  </li>
                  <li class="list-item ba-2">
                    <div class="label-item">
                      <p>Resigned</p>
                    </div>
                    <h3>{{ i.ResignedLadies_BA }}</h3>
                  </li>
                  <li class="list-item grey-1">
                    <div class="label-item">
                      <p>Recruiting</p>
                    </div>
                    <h3>{{ i.RecruitingLadies_BA }}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <daily
        :summary="getSummary"
        :summaryDailyReport="getSummaryDailyReport"
      />
    </div>
    <transition name="slide-fade">
      <overdays v-if="formActiveOverDays" @close="formActiveOverDays = false" />
    </transition>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import daily from "../components/summary/daily.vue";
import overdays from "../components/over14days/over-14days.vue";
export default {
  data: () => ({
    getSummary: [],
    getSummaryPending: [],
    getSummaryDailyReport: [],
    currentShop: [],
    currentUser: [],
    duration: [],
    date: "",
    selectMonth: "",
    month: "",
    selectedTab: "champa",
    recuritChampa: "",
    recuritBA: "",
    formActiveOverDays: false,
  }),
  components: {
    overdays,
    DatePicker,
    daily,
  },
  computed: {
    getMonth() {
      return (this.month = moment(this.month).format("YYYY-MM"));
    },
  },
  watch: {
    "$route.query.month": {
      handler(val) {
        this.FetchSummary();
      },
      immediate: true,
    },

    month: {
      handler(val) {
        const month = moment(val).format("YYYY-MM");
        this.$router
          .push({
            ...this.$route,
            query: { month },
          })
          .catch((err) => {});
        this.FetchSummary();
      },
    },
  },
  created() {
    const splitof = this.$route.query.month.split("-");
    const day = new Date(splitof[0], splitof[1] - 1, 1);
    this.month = day;
  },

  methods: {
    async FetchSummary() {
      let url = `${this.$server}api/summaryShop`;
      if (this.$route.query.month) {
        // const month = moment(this.$route.query.month).startOf("day");
        url = url + `?month=${this.$route.query.month}`;
      }
      const res = await this.$axios.get(url);
      this.getSummary = res.data.summary;
      this.getSummaryPending = res.data.summaryPendign;
      this.getSummaryDailyReport = res.data.summaryDailyReport;
      this.currentShop = res.data.currentShop;
      this.currentUser = res.data.currentUser;
      this.duration = res.data.summaryDuration;

      this.recuritChampa =
        this.currentShop[0].RecruitingLadies_Champa -
        this.currentUser[0].PendingLadies_Champa;
      this.recuritBA =
        this.currentShop[0].RecruitingLadies_BA -
        this.currentUser[0].PendingLadies_BA;
    },
  },
};
</script>

<style lang="scss" scoped>
// Report color
$champa-1: #002060;
$champa-2: #ffada2;
$ba-1: #3e5ac7;
$ba-2: #ffada2;
$grey-1: #e7e6e6;

.page-header-end {
  ul {
    li {
      border: 1px solid var(--border-color);
      padding: 5px 10px;
      font-size: var(--sm-font);
      cursor: pointer;
      background-color: rgba($ba-1, 0.15);
      color: $champa-1;
      &.active {
        background-color: $champa-1;
        color: #fff;
      }
      &.ba {
        background-color: rgba($champa-2, 0.15);
        color: $champa-2;
        &.active {
          background-color: $ba-2;
          color: #fff;
        }
      }
    }
  }
}

.overall {
  margin-bottom: 30px;
  padding: 30px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  background-color: var(--bg-color);
  .overall-card {
    h3 {
      font-weight: 700;
      font-size: var(--sm-font);
      margin-bottom: 10px;
    }
  }
  ul {
    display: grid;
    gap: 5px;
    li {
      // padding: 10px 15px;
      padding: 0;
      border-bottom: 1px solid var(--border-color);
      &:first-child {
        border-top: 1px solid var(--border-color);
      }
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: var(--xsm-font);
        }
        h3 {
          font-weight: 700;
          font-size: var(--xsm-font);
        }
      }
    }
  }
}

.page-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  .page-content {
    padding: 0;
    .page-header {
      padding: 10px 30px;
      margin: 0 !important;
      .page-header-start h3 {
        font-size: var(--lg-font);
      }
    }
    .card {
      padding: 20px 30px;
      box-shadow: none;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      display: flex;
      flex-direction: column;
      gap: 20px;
      .group-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        border-radius: 0;
      }
    }
  }
}

.grey-1 {
  background-color: $grey-1 !important;
  color: #000 !important;
}

.champa-1 {
  background-color: $champa-1 !important;
  color: #fff !important;
  &.child {
    background-color: rgba($champa-1, 0.67) !important;
    &::before,
    &::after {
      background-color: $champa-1;
    }
  }
}
.champa-2 {
  color: #000 !important;
  background-color: $champa-2 !important;
  &.child {
    background-color: rgba($champa-2, 0.67) !important;
    &::before,
    &::after {
      background-color: $champa-2;
    }
  }
}

.ba-2 {
  background-color: $ba-2 !important;
  color: #000 !important;
  &.child {
    background-color: rgba($ba-2, 0.67) !important;
    &::before,
    &::after {
      background-color: $ba-2;
    }
  }
}
.ba-1 {
  background-color: $ba-1 !important;
  color: #fff !important;
  &.child {
    background-color: rgba($ba-1, 0.8) !important;
    &::before,
    &::after {
      background-color: $ba-1;
    }
  }
}

.custom-yearmonth {
  display: flex;
  align-items: center;
  .control {
    margin-right: 10px;
  }
}
.list-container {
  .list-title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: var(--md-font);
    color: #000 !important;
    span {
      margin-right: 10px;
    }
    h3 {
      font-size: var(--md-font);
      margin: 0;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  ul li hr {
    margin: 5px 0 !important;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
    li.list-item {
      color: #fff;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 5px;
      position: relative;
      z-index: 999;
      &.child {
        margin-left: 20px;
        z-index: 1;
        &._x {
          &::before,
          &::after {
            content: none;
          }
        }
        &::before {
          content: "";
          width: 2px;
          height: 105%;
          position: absolute;
          left: -10px;
          top: -55%;
          z-index: 0;
        }
        &::after {
          content: "";
          width: 10px;
          height: 2px;
          position: absolute;
          left: -10px;
          top: 50%;
        }
      }
      .label-item {
        display: flex;
        align-items: center;
        font-size: var(--md-font);
        span {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(#fff, 0.15);
          border-radius: 5px;
          i {
            font-size: var(--md-font);
            font-weight: 700;
          }
        }
        p {
          line-height: 1.3;
        }
      } // label-item
      h3 {
        margin-left: auto;
        text-align: right;
        font-size: var(--md-font);
        font-weight: 700;
      }
    }
  }
}

.list-detail {
  display: flex;
  li {
    font-size: var(--sm-font);
    padding: 5px 0;
    border-bottom: 1px solid var(--border-color);
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 30%;
      text-align: center;
    }
    &:nth-child(4) {
      flex-grow: 1;
      text-align: right;
    }
  }
  &.head {
    li {
      font-weight: 700;
    }
  }
}
</style>
