<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="form scrollable-auto">
    <!-- <div v-if="$route.query.form_id">xxxx</div> -->

    <div v-if="!$route.query.form_id" class="form-header">
      <ul class="input-group">
        <li>
          <span>From</span>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <date-picker v-model="newStartDate" type="date" placeholder="Select Date" :class="{ errorText: errors[0] }"
              required>
            </date-picker>
            <span class="error is-error" v-if="errors[0]">
              please select date
            </span>
          </ValidationProvider>
        </li>
        <li>
          <span>To</span>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <date-picker :disabled-date="disabledDatesEndDate" v-model="newEndDate" type="date" placeholder="Select Date"
              :class="{ errorText: errors[0] }" required>
            </date-picker>
            <span class="error is-error" v-if="errors[0]">
              please select date
            </span>
          </ValidationProvider>
        </li>
        <li>
          <!-- <button class="button grey">Today</button> -->
        </li>
      </ul>
      <!-- <button class="button primary save">Save</button> -->
    </div>

    <!-- {{ newChampaId }} <br>
      {{ emitChampa }} <br>
      {{ newOutletId }} -->
    <!-- {{ champaResigned }} -->
    <!-- {{ newAttendanceStatus }} -->

    <div class="form-detail">
      <h3 class="form-title">
        <!-- <input type="text" class="input" placeholder="Employee ID" /> -->
        <label for="" class="label">Working ID</label>
        <div class="control" v-if="$route.query.form_id">
          <div class="select">
            <input class="input" type="text" :disabled="true"
              :value="`${newWorkingIDFormUser}` + ' - ' + `${newChampaName}`" v-if="newWorkingIDFormUser !== null" />
            <select v-model="newChampaId" disabled v-else>
              <option v-for="(i, index) in champa" :key="index" :value="i._id">
                {{ i.workingID }}
              </option>
            </select>
          </div>
        </div>
        <div class="control" v-else-if="$route.query.champaHistory || $route.query.baHistory">
          <input class="input" type="text" :disabled="true"
            :value="`${newWorkingIDFormUser}` + ' - ' + `${newChampaName}`" />
        </div>
        <div class="control" v-else>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <div>
              <selectsingle v-model="newChampaId" :items="champa" @valObject="emitChampaVal" single itemName="workingID"
                :class="{ errorText: errors[0] }" required />
            </div>
            <span class="error is-error" v-if="errors[0]">
              please select working ID
            </span>
          </ValidationProvider>
        </div>
      </h3>

      <!-- {{ newOutletId }} <br>
        {{ newChampaId }} <br>
        {{ newChampaName }} <br>
        {{ newWorkingIDFormUser }} <br> -->

      <div class="checklist">
        <label for="" class="label">Report Title</label>
        <div v-for="(i, idxAttendanceStatus) in attendanceStatus" :key="idxAttendanceStatus">
          <p v-if="newAttendanceStatus !== i._id" class="item"
            @click="SelectAttendanceStatus(i.name, idxAttendanceStatus, i._id)">
            <i class="fa-regular fa-circle"></i>
            {{ i.name }}
          </p>
          <p v-else class="item-checked" @click="SelectAttendanceStatus(i.name, idxAttendanceStatus, i._id)">
            <i class="fa-regular fa-circle"></i>
            {{ i.name }}
          </p>
        </div>
      </div>

      <ul class="grids is-3-desktop gap-20-desktop">
        <li>
          <div class="field">
            <label for="" class="label">Working Day (Assign/Resign)</label>
            <div class="control">
              <input v-model="newWorkingDate" type="number" class="input" placeholder="10" />
            </div>
          </div>
        </li>
        <!-- {{ instaWageFormatted }} <br> -->
        <!-- {{ newInstaWage }} <br> -->
        <li>
          <div class="field">
            <label for="" class="label">InstaWage</label>
            <div class="control">
              <input v-model="newInstaWage" type="text" class="input" placeholder="100000" />
            </div>
          </div>
        </li>
        <li>
          <div class="field">
            <label for="" class="label">InstaWage Fee</label>
            <div class="control">
              <input v-model="newFee" type="number" class="input" placeholder="100000" disabled v-if="!newInstaWage" />

              <ValidationProvider v-slot="{ errors }" rules="required" v-else>
                <input v-model="newFee" type="number" class="input" placeholder="100000" :class="{ errorText: errors[0] }"
                  required />
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="field">
            <label for="" class="label">Remark (Optional)</label>
            <div class="control">
              <input v-model="newFreeText" type="text" class="input" placeholder="ຂໍ້ຄວາມຕາມໃຈ" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="button-group" v-if="$route.query.form_id">
      <button class="button primary" @click="handleSubmit(UpdateAdminDefCheck)">
        Update
      </button>
      <button class="close button grey" @click="close()">Cancel</button>
    </div>
    <div class="button-group" v-else>
      <button class="button primary save" @click="handleSubmit(AddAdminDefCheck)">
        Save
      </button>
      <button class="close button grey" @click="closeFormAddNew()">
        Cancel
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import selectsingle from "@coms/selectsingle-search.vue";
export default {
  props: {
    champaResigned: {
      type: Object,
    },
    // formDailyReport: String
  },

  data: () => ({
    attendanceStatus: [
      // {
      //   _id: "1",
      //   name: "ຂາດວຽກ - ບໍ່ມີເຫດຜົນ  ( ຕັດເງີນ 100% )",
      // },
      // {
      //   _id: "2",
      //   name: "ຄອບວຽກ - ມີເຫດຜົນ 1 ມື້  ( ຕັດເງີນ 50% )",
      // },
      // {
      //   _id: "3",
      //   name: "ຄອບວຽກ - ມີເຫດຜົນ ເຄີ່ງມື້  ( ຕັດເງິນ 25% )",
      // },
      // {
      //   _id: "4",
      //   name: "ລາພັກປະຈຳປີ - ບໍ່ຕັດເງິນ ( ກໍລະນີມີສິດມື້ພັກ )",
      // },
      // {
      //   _id: "5",
      //   name: "ເຂົ້າວຽກແທນມື້ພັກ",
      // },
      // {
      //   _id: "6",
      //   name:
      //     "ຮ້ານປິດ - ບໍ່ຕັດເງິນ ( ຕ້ອງມີຮູບຫຼັກຖານການປິດ ແລະ ຢັ້ງຢືນຈາກເຈົ້າຂອງຮ້ານ )",
      // },
    ],
    eiei: "",
    emitChampa: "",
    champa: [],
    dailyreport: {},
    newWorkingIDFormUser: "",
    newWorkingID: "",
    newAttendanceStatus: "",
    newShopStatus: "",
    newCovidStatus: "",
    newFreeText: "",
    newInstaWage: "",
    newFee: "",
    newDailyReportStatus: "",
    newWorkingDate: "",
    newChampaId: "",
    newChampaName: "",
    newOutletId: "",
    isChecked: "",
    newStartDate: new Date(),
    newEndDate: new Date(),
  }),
  components: {
    DatePicker,
    selectsingle,
  },

  watch: {
    // newInstaWage() {
    //   const formatter = new Intl.NumberFormat();
    //   formatter.format(this.newInstaWage);
    // },

    formDailyReport(val) {
      if (val) {
        this.newAttendanceStatus = "";
        this.newShopStatus = "";
        this.newCovidStatus = "";
        this.newChampaId = "";
        this.newFreeText = "";
        this.newWorkingDate = "";
      }
    },
    "$route.query.form_id": {
      handler(val) {
        if (val) {
          this.FetchAdminDefCheckId();
        }
      },
      immediate: true,
    },

    newStartDate(newValue) {
      if (newValue) {
        this.newEndDate = this.newStartDate;
      }
    },
  },

  created() {
    this.FetchChampa();
    this.FetchReportTitle();

    //
    //ໄດ້ຂໍ້ມູນມາຈາກ ChampaHistory ເພື່ອເພີ່ມຂໍ້ມູນ DailyReport
    if (this.champaResigned) {
      this.newOutletId = this.champaResigned.shopId;
      this.newChampaId = this.champaResigned.userId;
      this.newChampaName = this.champaResigned.fullname;
      this.newWorkingIDFormUser = this.champaResigned.workingID;
    }
  },
  methods: {
    // format(e) {
    //   return e.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // },
    // saveInput(e) {
    //   this.newInstaWage = parseInt(e.target.value.replaceAll(',', ''));
    // },

    //
    //ຂໍ້ມຸນມາຈາກການ emit selectsingle ເພື່ອເພີ່ມຂໍ້ມູນ DailyReport
    emitChampaVal(val) {
      this.emitChampa = val;
      this.newOutletId = this.emitChampa.shopId;
      this.newWorkingIDFormUser = this.emitChampa.defaultWorkingID;
    },
    async SelectAttendanceStatus(value, idx, valueId) {
      this.newAttendanceStatus = valueId;
    },

    disabledDatesEndDate(date) {
      return (
        date <=
        new Date(
          new Date(this.newStartDate).setDate(
            new Date(this.newStartDate).getDate() - 1
          )
        )
      );
    },
    close() {
      this.$emit("close");
      this.$router.push({
        query: {
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
          id: this.$route.query.id,
        },
      });
    },
    closeFormAddNew() {
      if (this.$route.query.add === "add") {
        this.$emit("close");
        this.$router.push({
          query: {
            startDate: "",
            endDate: "",
          },
        });
      } else {
        this.$emit("close");
      }
    },

    async FetchReportTitle() {
      const res = await this.$axios.get(`${this.$server}api/get-report-title`);
      this.attendanceStatus = res.data.getReportTitle;
      if (!this.$route.query.form_id) {
        this.attendanceStatus = this.attendanceStatus;
      }
    },
    async FetchChampa() {
      const res = await this.$axios.get(`${this.$server}api/user-get`);
      this.champa = res.data.filterUser;
    },
    FetchAdminDefCheckId() {
      this.$axios
        .get(
          `${this.$server}api/daily-report-find-id/` + this.$route.query.form_id
        )
        .then((res) => {
          this.dailyreport = res.data.findAdminDefCheckId;
          this.newChampaId = this.dailyreport.userId;
          // this.newAttendanceStatus = "";
          this.newShopStatus = this.dailyreport.shopStatus;
          this.newCovidStatus = this.dailyreport.covidStatus;
          this.newFreeText = this.dailyreport.freeText;
          this.newDailyReportStatus = this.dailyreport.dailyReportStatus;
          this.newInstaWage = this.dailyreport.instaWage;
          this.newFee = this.dailyreport.fee;
          this.newWorkingDate = this.dailyreport.workingDate;
          this.newWorkingID = this.dailyreport.workingID;
          this.newAttendanceStatus =
            this.dailyreport.reportTitle.length > 0
              ? this.dailyreport.reportTitle[0]["_id"]
              : [];

          this.newChampaName = this.dailyreport.fullname;
          this.newWorkingIDFormUser = this.dailyreport.workingIDFormUser
            ? this.dailyreport.workingIDFormUser
            : this.dailyreport.workingID;
        });
    },
    AddAdminDefCheck() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      if (this.champaResigned) {
        this.newDailyReportStatus = "Resigned";
      }
      this.$axios
        .post(`${this.$server}api/daily-report-add`, {
          workingIDFormUser: this.newWorkingIDFormUser,
          attendanceStatus: "",
          shopStatus: this.newShopStatus,
          covidStatus: this.newCovidStatus,
          freeText: this.newFreeText,
          instaWage: this.newInstaWage,
          fee: this.newFee,
          dailyReportStatus: this.newDailyReportStatus,
          workingDate: this.newWorkingDate,
          userId: this.newChampaId,
          shopId: this.newOutletId,
          startDate: this.newStartDate,
          endDate: this.newEndDate,
          reportTitleId: this.newAttendanceStatus,
        })
        .then(() => {
          setTimeout(() => {
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
            if (this.$route.query.add === "add") {
              this.$emit("close");
              this.$router.push({
                query: {
                  startDate: this.$route.query.startDate,
                  endDate: this.$route.query.endDate,
                },
              });
            } else {
              this.$emit("close");
            }
          }, 500);
        });
    },
    async UpdateAdminDefCheck() {
      //
      //Alert Loading
      this.$swal({
        title: "Loading...",
        html: "Please wait",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      })

      const update = {
        id: this.$route.query.form_id,
        attendanceStatus: "",
        shopStatus: this.newShopStatus,
        covidStatus: this.newCovidStatus,

        freeText: this.newFreeText,
        instaWage: this.newInstaWage,
        fee: this.newFee,
        workingDate: this.newWorkingDate,
        reportTitleId: this.newAttendanceStatus,
      };
      await this.$axios
        .put(`${this.$server}api/daily-report-update`, update)
        .then(() => {
          setTimeout(() => {
            this.$swal({
              position: "top",
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000,
            });
            this.$emit("close");
            this.$router.push({
              query: {
                startDate: this.$route.query.startDate,
                endDate: this.$route.query.endDate,
                id: this.$route.query.id,
              },
            });
          }, 500);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.checklist {
  margin-bottom: 20px;

  .item {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 10px;
    height: 1.8rem;
    transition: all ease-in-out 0.18s;
    font-size: var(--md-font);

    i {
      color: var(--font-color);
    }
  }

  .item-checked {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 1.8rem;
    color: var(--primary-color);

    i {
      font-weight: 700;
      color: var(--primary-color);
    }
  }
}

.form {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.19s;
}

.form-header {
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
  margin-bottom: 20px;
  width: 100%;

  .input-group {
    display: flex;
    width: 100%;

    li {
      width: 250px;
      display: flex;
      align-items: center;
      margin-right: 10px;

      span {
        padding-right: 10px;
      }
    }

    .save {
      margin-left: auto;
    }
  }
}

.form-detail {
  .form-title {
    margin-bottom: 20px;

    .control {
      .disabled {
        cursor: no-drop;
        pointer-events: none;
        color: var(--grey-color);
      }
    }
  }

  .label {
    display: block;
    margin-bottom: 5px !important;
    font-weight: 500;
    color: var(--font-color);
    font-size: var(--md-font);
  }

  ul {
    margin-bottom: 20px;
  }
}

.modal_ {
  position: absolute;
  max-width: 30%;
  right: 0;
  background-color: red;
}
</style>
