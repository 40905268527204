<template>
  <div class="page-container">
    <ValidationObserver v-slot="{ handleSubmit }" tag="div">
      <div class="sub-sidebar" style="width: 250px;">
        <div class="sub-sidebar-header">
          <div class="button-group">
            <button class="button primary sm" @click="
              formDailyReport = true;
            $router
              .push({
                query: {
                  startDate: $route.query.startDate,
                  endDate: $route.query.endDate,
                  add: 'add',
                },
              })
              .catch(() => { });
            ">
              Add new
            </button>
            <button class="button light-grey sm">
              <i class="fal fa-file-excel" @click="handleSubmit(ExportExcelDailyReport)"></i>
            </button>
          </div>
          <ul>
            <li>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <date-picker v-model="startDate" type="date" placeholder="Start Date" class="xsm-text"
                  :class="{ errorText: errors[0] }" required>
                </date-picker>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </li>
            <li>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <date-picker :disabled-date="disabledDatesEndDate" v-model="endDate" class="xsm-text" type="date"
                  placeholder="End Date" :class="{ errorText: errors[0] }" required>
                </date-picker>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </li>
          </ul>
        </div>
        <div class="sub-sidebar-list scrollable">
          <ul>
            <li v-for="(i, index) in groupDate" :key="index" :class="{ selected: i._id === $route.query.id }" @click="

              formDailyReport = false;
            selectComponent = 'DailyReportList';
            $router
              .push({
                query: {
                  startDate: $route.query.startDate,
                  endDate: $route.query.endDate,
                  id: i._id,
                },
              })
              .catch((err) => { });
            ">
              <span>{{ i.date }}</span>
              <p>
                <span>{{ i.count }} </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </ValidationObserver>

    <div class="page-content">
      <DailyReportList v-if="!$route.query.form_id && !formDailyReport" />

      <transition name="slide-fade">
        <DailyReportForm :formDailyReport="formDailyReport" v-if="$route.query.form_id || formDailyReport"
          @close="formDailyReport = false" />
      </transition>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import DailyReportList from "../components/daily-report-list.vue";
import DailyReportForm from "../components/form/daily-report";
import moment from "moment";
export default {
  components: {
    DatePicker,
    DailyReportList,
    DailyReportForm,
  },
  data: () => ({
    selectComponent: "",
    formDailyReport: false,
    groupDate: [],
    startDate: "",
    endDate: "",
  }),
  watch: {
    "$route.query.startDate": {
      handler(val) {
        this.FetchGroupDate();
      },
      immediate: true,
    },
    startDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { startDate: val },
          })
          .catch((err) => { });
        this.FetchGroupDate();
      },
    },
    "$route.query.endDate": {
      handler(val) {
        this.FetchGroupDate();
      },
      immediate: true,
    },
    endDate: {
      handler(val) {
        this.$router
          .push({
            ...this.$route,
            query: { endDate: val },
          })
          .catch((err) => { });
        this.FetchGroupDate();
      },
    },
    $route() {
      this.FetchGroupDate();
    },
  },
  // created() {
  //   this.FetchGroupDate();
  // },
  methods: {
    disabledDatesEndDate(date) {
      return (
        date <=
        new Date(
          new Date(this.startDate).setDate(
            new Date(this.startDate).getDate() - 1
          )
        )
      );
    },
    cancel() {
      (this.DailyReportForm = false),
        this.$router.push({
          ...this.$route,
          query: { id: this.$route.query.id },
        });
    },
    async FetchGroupDate() {
      const res = await this.$axios.get(
        `${this.$server}api/daily-report-group-date?startDate=${this.startDate}&endDate=${this.endDate}`
      );
      this.groupDate = res.data.mapGroupDate;
    },
    async ExportExcelDailyReport() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        const startDate = moment(this.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.endDate).format("YYYY-MM-DD");

        const res = await this.$axios.post(
          `${this.$server}api/export-dailyreport-group-champaname?startDate=${startDate}&endDate=${endDate}`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );

        setTimeout(() => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "DailyReport.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          this.$emit("closeExport");
        }, 500);
        // await this.$store.dispatch("completed")
      } catch (err) {
        // await this.$store.dispatch("error")
        throw new Error(err);
      }
    },


    // async FetchDeleteDate(id) {
    //   await this.$axios.get(`${this.$server}api/daily-report-get?dateId=${id}`);
    //   this.FetchGroupDate();
    // }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  padding: 0 !important;

  .page-content {
    padding: 0 !important;
    width: 100%;
    overflow-y: hidden;
  }
}
</style>
