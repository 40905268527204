<template>
  <div class="table-container">
    <table class="table is-fullwidth">
      <thead>
        <tr class="sticky">
          <th>Working ID</th>
          <th>Champa Name</th>
          <th>Outlet Name</th>
          <th>Report Detail</th>
          <th>Working Day</th>
          <th>InstaWage</th>
          <th>Supervisor</th>
          <th>Option</th>
        </tr>
      </thead>

      <tbody v-if="$route.query.id">
        <tr v-for="(i, index) in groupDateId" :key="index">
          <td @click="
            formDailyReport = true;
          $router
            .push({
              ...$route,
              query: {
                ...$route.query,
                form_id: i._id,
              },
            })
            .catch((err) => { });
          ">
            {{ i.resignedWorikingID }}
          </td>
          <td>{{ i.fullname }}</td>
          <td>{{ i.shop ? i.shop.name : "" }}</td>
          <td>{{ i.reportTitle.length > 0 ? i.reportTitle[0]["name"] : i.reportDetail }}</td>
          <td>{{ i.workingDate }}</td>
          <td>{{ i.arrInstaWage }}</td>
          <td>{{ i.resignedAdmin }}</td>
          <td>
            <span @click="
              DeleteAdminDefCheck(i._id);
            $router
              .push({
                ...$route,
                query: {
                  ...$route.query,
                  delete_id: i._id
                },
              })
              .catch((err) => { });
            " class="icon alert">
              <i class="fa-solid fa-trash"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <DailyReportForm v-if="formDailyReport" @close="formDailyReport = false" />
  </div>
</template>

<script>
import DailyReportForm from "../components/form/daily-report";

export default {
  data: () => ({
    groupDateId: [],
    formDailyReport: false,
  }),
  components: {
    DailyReportForm,
  },
  watch: {
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.FetchGroupDateId();
        }
      },
      immediate: true,
    },
  },
  methods: {
    FetchGroupDateId() {
      this.$axios
        .get(`${this.$server}api/find-id-groupdate/` + this.$route.query.id)
        .then((res) => {
          const val = res.data.findIdGroupDate;
          this.groupDateId = val.map((i) => {
            return {
              ...i,
              resignedWorikingID: i.workingIDFormUser !== null ? i.workingIDFormUser : i.workingID,
              resignedAdmin: i.workingID === null && i.shop !== null ? i.shop.adminId.name : i.adminName,
            }
          })
        });
    },
    async DeleteAdminDefCheck(_id) {
      await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.delete(`${this.$server}api/daily-report-delete/${_id}`);
          this.$swal({
            position: "top",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1000,
          });
          this.FetchGroupDateId();
          this.$router.push({
            query: {
              startDate: this.$route.query.startDate,
              endDate: this.$route.query.endDate,
              id: this.$route.query.id,
            },
          });
          // setTimeout(() => {
          //   location.reload();
          // }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
}

.is-fullwidth {
  width: 100%;

  thead tr.sticky {
    position: sticky;
    top: 0;
  }

  th {
    background-color: var(--bg-color);
  }

  th,
  td {
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: var(--xsm-font) !important;
  }
}
</style>
