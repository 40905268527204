var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"sub-sidebar",staticStyle:{"width":"250px"}},[_c('div',{staticClass:"sub-sidebar-header"},[_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary sm",on:{"click":function($event){_vm.formDailyReport = true;
          _vm.$router
            .push({
              query: {
                startDate: _vm.$route.query.startDate,
                endDate: _vm.$route.query.endDate,
                add: 'add',
              },
            })
            .catch(function () { });}}},[_vm._v(" Add new ")]),_c('button',{staticClass:"button light-grey sm"},[_c('i',{staticClass:"fal fa-file-excel",on:{"click":function($event){return handleSubmit(_vm.ExportExcelDailyReport)}}})])]),_c('ul',[_c('li',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('date-picker',{staticClass:"xsm-text",class:{ errorText: errors[0] },attrs:{"type":"date","placeholder":"Start Date","required":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1),_c('li',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('date-picker',{staticClass:"xsm-text",class:{ errorText: errors[0] },attrs:{"disabled-date":_vm.disabledDatesEndDate,"type":"date","placeholder":"End Date","required":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),(errors[0])?_c('span',{staticClass:"error is-error"},[_vm._v(" required ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"sub-sidebar-list scrollable"},[_c('ul',_vm._l((_vm.groupDate),function(i,index){return _c('li',{key:index,class:{ selected: i._id === _vm.$route.query.id },on:{"click":function($event){_vm.formDailyReport = false;
          _vm.selectComponent = 'DailyReportList';
          _vm.$router
            .push({
              query: {
                startDate: _vm.$route.query.startDate,
                endDate: _vm.$route.query.endDate,
                id: i._id,
              },
            })
            .catch(function (err) { });}}},[_c('span',[_vm._v(_vm._s(i.date))]),_c('p',[_c('span',[_vm._v(_vm._s(i.count)+" ")])])])}),0)])])]}}])}),_c('div',{staticClass:"page-content"},[(!_vm.$route.query.form_id && !_vm.formDailyReport)?_c('DailyReportList'):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.$route.query.form_id || _vm.formDailyReport)?_c('DailyReportForm',{attrs:{"formDailyReport":_vm.formDailyReport},on:{"close":function($event){_vm.formDailyReport = false}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }