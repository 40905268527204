var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),(_vm.$route.query.id)?_c('tbody',_vm._l((_vm.groupDateId),function(i,index){return _c('tr',{key:index},[_c('td',{on:{"click":function($event){_vm.formDailyReport = true;
        _vm.$router
          .push(Object.assign({}, _vm.$route,
            {query: Object.assign({}, _vm.$route.query,
              {form_id: i._id})}))
          .catch(function (err) { });}}},[_vm._v(" "+_vm._s(i.resignedWorikingID)+" ")]),_c('td',[_vm._v(_vm._s(i.fullname))]),_c('td',[_vm._v(_vm._s(i.shop ? i.shop.name : ""))]),_c('td',[_vm._v(_vm._s(i.reportTitle.length > 0 ? i.reportTitle[0]["name"] : i.reportDetail))]),_c('td',[_vm._v(_vm._s(i.workingDate))]),_c('td',[_vm._v(_vm._s(i.arrInstaWage))]),_c('td',[_vm._v(_vm._s(i.resignedAdmin))]),_c('td',[_c('span',{staticClass:"icon alert",on:{"click":function($event){_vm.DeleteAdminDefCheck(i._id);
          _vm.$router
            .push(Object.assign({}, _vm.$route,
              {query: Object.assign({}, _vm.$route.query,
                {delete_id: i._id})}))
            .catch(function (err) { });}}},[_c('i',{staticClass:"fa-solid fa-trash"})])])])}),0):_vm._e()]),(_vm.formDailyReport)?_c('DailyReportForm',{on:{"close":function($event){_vm.formDailyReport = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"sticky"},[_c('th',[_vm._v("Working ID")]),_c('th',[_vm._v("Champa Name")]),_c('th',[_vm._v("Outlet Name")]),_c('th',[_vm._v("Report Detail")]),_c('th',[_vm._v("Working Day")]),_c('th',[_vm._v("InstaWage")]),_c('th',[_vm._v("Supervisor")]),_c('th',[_vm._v("Option")])])])}]

export { render, staticRenderFns }