<template>
  <div class="page-container">
    <div class="page-content" :class="{ small: formActive }">
      <div class="page-header" v-if="!formActive">
        <div class="page-header-start">
          <h3>BA - Outlet</h3>
          <div class="button-group">
            <button
              class="button primary"
              @click="
                formActive = true;
                $router
                  .push({
                    ...$route,
                    query: {
                      ...$route.query,
                      add: 'add',
                    },
                  })
                  .catch((err) => {});
              "
            >
              Add
            </button>

            <button class="button light-grey" @click="ExportOutletBA">
              Export
            </button>
          </div>
        </div>

        <div class="page-header-end">
          <input
            v-model="searchOutlet"
            type="text"
            class="input"
            placeholder="Search..."
          />
        </div>

        <div class="page-header-end">
          <ul>
            <li>
              <p>All Outlet:</p>
              <h3>{{ totalShopBA }}</h3>
            </li>
            <li>
              <div class="select">
                <supervisor
                  v-model="selectedSupervisor"
                  :data="supervisor"
                  itemName="name"
                />
              </div>
            </li>
            <li>
              <div class="select">
                <supervisor
                  v-model="selectedStatus"
                  :data="status"
                  itemName="name"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="table-container scrollable">
        <table class="table">
          <thead>
            <tr>
              <th class="is-xs">Outlet Code</th>
              <th class="is-sm">Outlet Name</th>
              <th class="is-sm">Outlet Status</th>
              <th class="is-sm">Recurit Date</th>
              <th class="is-sm">Quit Date</th>
              <th class="is-xxs">DT Code</th>
              <th class="is-xs">Working ID</th>
              <th class="is-xs">Employee ID</th>
              <th class="is-xs">Champa Name</th>
              <th class="is-xs">Join Date</th>
              <th class="is-xs text-right">Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in getShop" :key="index">
              <td
                class="clickable"
                @click="
                  formActive = true;
                  $router
                    .push({
                      query: {
                        selectedSupervisor: $route.query.selectedSupervisor,
                        selectedStatus: $route.query.selectedStatus,
                        page: $route.query.page,
                        perPage: pages.perPage,
                        searchOutlet: $route.query.searchOutlet,
                        id: i._id,
                      },
                    })
                    .catch((err) => {});
                "
              >
                {{ i.shopCode }}
              </td>
              <td>{{ i.name }}</td>
              <td>{{ getChampaOutlet(i.status) }}</td>
              <td>{{ formatDate(i.recruitDate) }}</td>
              <td>{{ formatDate(i.quitDate) }}</td>
              <td>{{ i.zoneCode }}</td>
              <td>{{ i.workingID }}</td>
              <td>{{ i.employeeID }}</td>
              <td>{{ i.fullname }}</td>
              <td>{{ formatDate(i.joinDate) }}</td>
              <td class="text-right">{{ i.adminName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- pagination -->
      <paginate v-model="page" :total="all" />
    </div>

    <transition name="slide-fade">
      <!-- <component
      v-if="formActive"
      :is="'outletForm'"
      @close="formActive = false"
    ></component> -->

      <outletForm v-if="formActive" @close="formActive = false" />
    </transition>

    <!-- <export-date
      v-if="formActiveExport"
      @closeExport="formActiveExport = false"
    /> -->
  </div>
</template>

<script>
import paginate from "../components/paginate.vue";
import supervisor from "../components/filter/filter.vue";
import outletForm from "../components/form/outlet.vue";
import ExportDate from "../components/export/export-date.vue";
import moment from "moment";
export default {
  components: {
    paginate,
    supervisor,
    outletForm,
    ExportDate,
  },
  data: () => ({
    status: [
      {
        _id: "setup",
        name: "Setup",
      },
      {
        _id: "pending",
        name: "No BA",
      },
      {
        _id: "active",
        name: "Active",
      },
      {
        _id: "quit",
        name: "Quit",
      },
    ],
    formActive: false,
    formActiveExport: false,
    getShop: [],
    supervisor: [],
    totalShopBA: "",
    pages: {
      perPage: 10,
    },
    page: 1,
    all: null,
    selectedSupervisor: "",
    selectedStatus: "",
    searchOutlet: "",
  }),
  watch: {
    "$route.query.status": {
      handler(val) {
        if (val === "close") {
          this.formActive = false;
        }
      },
    },
    //supervisor
    "$route.query.selectedSupervisor": {
      handler() {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedSupervisor: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$router,
          query: {
            ...this.$route.query,
            selectedSupervisor: val,
          },
        });
      },
    },
    //status
    "$route.query.selectedStatus": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    selectedStatus: {
      handler(val) {
        this.page = 1;
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, selectedStatus: val },
        });
      },
    },
    //paginations
    "$route.query.page": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, page: val },
        });
      },
    },
    //search
    "$route.query.searchOutlet": {
      handler(val) {
        this.FetchShop();
      },
      immediate: true,
    },
    searchOutlet: {
      handler(val) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, searchOutlet: val },
        });
      },
    },
    $route() {
      this.FetchShop();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
  },
  created() {
    this.FetchShop();
    this.FetchSupervisor();
  },
  computed: {
    getChampaOutlet() {
      // const n = [{
      //   oldName: 'active',
      //   newName: 'ssj'
      // }]
      return (val) => {
        if (val === "pending") return "no ba";
        return val;
      };
    },
    formatDate() {
      return (val) => {
        if (val) return moment(val).format("YYYY-MM-DD");
      };
    },
  },
  methods: {
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.supervisorAll = res.data.mapAdmin;
      this.supervisor = this.supervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchShop() {
      const res = await this.$axios.get(
        `${this.$server}api/aggre-shop-ba?adminId=${this.$route.query.selectedSupervisor}&status=${this.$route.query.selectedStatus}&page=${this.$route.query.page}&perPage=${this.pages.perPage}&search=${this.$route.query.searchOutlet}`
      );
      this.getShop = res.data.aggreShopBA;
      this.totalShopBA = res.data.total;
      this.all = res.data.total;
    },
    async ExportOutletBA() {
      try {
        // await this.$store.dispatch("loading");
        // this.$axios.defaults.headers["Authorization"] = this.getToken;
        // const startDate = moment(this.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.endDate).format("YYYY-MM-DD");
        const res = await this.$axios.post(
          `${this.$server}api/export-shop-BA`,
          {
            //  monthlyPaymentId: this.$route.params.id
          },
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "OutletBA.xlsx");
        document.body.appendChild(link);
        link.click();
        this.$swal({
          position: "top",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1000,
        });
        // this.$emit("closeExport");
        // await this.$store.dispatch("completed")
      } catch (error) {
        const errorText = response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 0 10px 0 0;
  .table {
    width: 100%;
  }
  .table tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
